<template>
    <!-- HORIZONTAL LAYOUT -->
    <vx-card title="Update Work Order">
        <div class="vx-row mb-6" style="width: 50%">
            <vs-button class="ml-4 mt-2" color="danger" icon-pack="feather" icon="icon-arrow-left"
                @click="handleBack()">Back</vs-button>
        </div>
        <div class="vx-row">
            <div class="vx-col sm:w-1/2 w-full mb-base">
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/4 w-full">
                        <span>Work Order ID </span>
                    </div>
                    <div class="vx-col sm:w-2/5 w-full">
                        <vs-input class="w-full" v-model="code" disabled />
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/4 w-full">
                        <span>Work Order Description</span>
                    </div>
                    <div class="vx-col sm:w-2/5 w-full">
                        <vs-input class="w-full" v-model="description" />
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/4 w-full">
                        <span>Bill Of Material ID</span>
                    </div>
                    <div class="vx-col sm:w-2/5 w-full">
                        <multiselect class="selectExample" v-model="selectedBom" :options="optionBom" :multiple="false"
                            :filterable="true" :allow-empty="true" :group-select="true" :max-height="100" :limit="3"
                            :internal-search="false" placeholder="Type to search" :select-label="''"
                            :deselect-label="''" :searchable="true" track-by="id" label="Code" @select="handleBoM()" />
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/4 w-full">
                        <span>Purpose</span>
                    </div>
                    <div class="vx-col sm:w-2/5 w-full">
                        <!-- <multiselect class="selectExample" v-model="selectedBom" :options="optionBom" :multiple="false"
                            :filterable="true" :allow-empty="false" :group-select="false" :max-height="100" :limit="3"
                            :internal-search="false" :select-label="''" :deselect-label="''"
                            placeholder="Type to search" :searchable="true" track-by="id" label="Code"
                            @select="handleBoM()" /> -->
                        <multiselect :allowEmpty="false" :options="optionPurpose" v-model="selectedPurpose"
                            :select-label="''" :deselect-label="''" :label="'name'" :track-by="'name'" :placeholder="''"
                            @input="(val) => {
                                onChangePurpose(val);
                            }" :disabled="selectedBom ? true : false" />
                    </div>
                </div>

                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/4 w-full">
                        <span>Warehouse</span>
                    </div>
                    <div class="vx-col sm:w-2/5 w-full">
                        <multiselect class="selectExample" v-model="selectedWarehouse" :options="optionWarehouse"
                            :multiple="false" :allow-empty="false" :group-select="false" :max-height="100" :limit="3"
                            placeholder="Type to search" :select-label="''" :deselect-label="''" :searchable="true"
                            :custom-label="customLabelWarehouse" @input="(val) => {
                                onChangeWarehouse(val.WarehouseID);
                            }" />
                    </div>
                </div>

                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/4 w-full">
                        <span>Warehouse Area</span>
                    </div>
                    <div class="vx-col sm:w-2/5 w-full">
                        <multiselect class="selectExample" v-model="selectedWarehouseArea"
                            :options="optionWarehouseArea" :multiple="false" :select-label="''" :deselect-label="''"
                            :allow-empty="false" :group-select="false" :max-height="100" :limit="3"
                            placeholder="Type to search" :searchable="true" :custom-label="customLabelWarehouseArea"
                            @input="(val) => {
                                onChangeWarehouseArea();
                            }" />
                    </div>
                </div>

                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/4 w-full">
                        <span>Request Date</span>
                    </div>
                    <div class="vx-col sm:w-2/5 w-full">
                        <!-- <vs-input v-model="requestDate" class="w-full" type="date" name="requestDate"
                            :min="now"></vs-input> -->
                        <datepicker name="date" :inline="false" format="yyyy-MM-dd" v-model="requestDate"
                            placeholder="Select Date" :disabledDates="disabledDatesRequest" :cleared="() => {
                                requestDate = null
                            }">
                        </datepicker>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/4 w-full">
                        <span>Execution Date</span>
                    </div>
                    <div class="vx-col sm:w-2/5 w-full">
                        <datepicker name="date" :inline="false" format="yyyy-MM-dd" v-model="executionDate"
                            placeholder="Select Date" :disabledDates="disabledDatesExecution">
                        </datepicker>
                        <!-- <vs-input v-model="executionDate" class="w-full" type="date" name="executionDate"
                            :min="minExecutionDate"></vs-input> -->
                    </div>
                </div>
            </div>

            <div class="vx-col sm:w-1/2 w-full mb-base">
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/4 w-full">
                        <span>Note</span>
                    </div>
                    <div class="vx-col sm:w-2/5 w-full">
                        <vs-textarea v-model="note" class="w-full" type="date" name="note"></vs-textarea>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/4 w-full">
                        <label>Attachment
                            <small style="color: red">(only: xlsx, xls, jpg, jpeg, png, pdf, mp4, avi, mkv, 3gp, m4v,
                                wmv, mov)</small></label>
                    </div>
                    <div class="vx-col sm:w-3/4 w-full">
                        <div class="vx-col w-1/2">
                            <!-- only accept "jpg", "jpeg", "pdf", "doc", "docx", "png" , "xlsx", "xls"-->
                            <input id="fileInput" name="file" class="w-full inputx" type="file" ref="file"
                                multiple="multiple"
                                accept=".xlsx, .xls, .jpg, .jpeg, .png, .pdf, .mp4, .avi, .mkv, .3gp, .m4v, .wmv, .mov" />
                        </div>
                    </div>
                </div>
                <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
                    <div class="vx-col sm:w-1/4 w-full">
                        <span></span>
                    </div>
                    <div class="vx-col sm:w-3/4 w-full">
                        <div class="vx-col sm:w-4/5 w-full">
                            <vs-button class="mr-3 mb-2" @click="handleAttachment">Add Attachment</vs-button>
                        </div>
                    </div>
                </div>
                <!-- display: block -->
                <vs-divider style="width: 60%; margin-left: 30%">
                    List Attachment
                </vs-divider>
                <div class="vx-row mb-3 mt-6" style="width: 60%; margin-left: 30%">
                    <table class="vs-table vs-table--tbody-table">
                        <template>
                            <tr class="tr-values vs-table--tr tr-table-state-null selected" v-bind:key="tr.NameFile"
                                v-for="(tr, i) in fileAttachment">
                                <td class="td vs-table--td">{{ tr.NameFile }}</td>
                                <td class="td vs-table--td">
                                    <vx-tooltip text="Show" v-if="tr.PathFile != ''">
                                        <vs-button type="line" icon-pack="feather" icon="icon-eye"
                                            @click.stop="handleShowAttachment(tr)" />
                                    </vx-tooltip>
                                </td>

                                <td class="td vs-table--td">
                                    <template>
                                        <vx-tooltip text="Delete">
                                            <vs-button type="line" icon-pack="feather" icon="icon-trash"
                                                @click.stop="handleDeleteAttachment(tr.id, i)" />
                                        </vx-tooltip>
                                    </template>
                                </td>
                            </tr>
                        </template>
                    </table>
                </div>
            </div>
        </div>
        <h3><b>Parent (SKU Result)</b></h3>
        <hr />
        <br />
        <div class="vx-row">
            <div class="vx-col sm:w-1/1 w-full mb-base m-10">
                <table width="100%" class="vs-table vs-table--tbody-table">
                    <thead class="vs-table--thead">
                        <tr>
                            <th width="25%">SKU Description</th>
                            <!-- <th width="30%">SKU Name</th> -->
                            <th width="5%">Qty</th>
                            <th width="5%">Unit</th>
                            <th width="5%">Qty UOM</th>
                            <th width="10%">Supplier</th>
                            <th width="10%">MAP</th>
                            <th width="10%">Sub Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td v-if="selectedBom && changedBom" class="td vs-table--td align-top" style="padding: 5px">
                                <vs-input class="w-full" :value="`${itemParent.sku_code} ${itemParent.name}`"
                                    disabled />
                            </td>
                            <td v-else class="td vs-table--td" style="padding: 5px">
                                <multiselect :allowEmpty="false" :options="itemParent.optionItem"
                                    v-model="itemParent.selected_items" :select-label="''" :deselect-label="''"
                                    :label="'label'" :track-by="'label'"
                                    :placeholder="'Please type SKU Code or SKU Name'" @input="(val) => {
                                        onChangeSku(val, 0, 'parent');
                                    }" @search-change="(val) => {
                                        handleSearchLines(val, 0, 'parent');
                                    }" :disabled="selectedBom != null" />
                            </td>
                            <!-- <td class="td vs-table--td" style="padding: 5px">
                                <vs-input class="w-full" v-model="itemParent.name" disabled />
                            </td> -->
                            <td class="td vs-table--td" style="padding-right: 5px">
                                <vs-input class="w-full" type="number" v-model="itemParent.qty" @input="(val) => {
                                    onChangeQty(val, 0, 'parent');
                                }" :min="1" :disabled="disableParentQty || selectedBom != null" />
                            </td>
                            <td v-if="selectedBom && changedBom" class="td vs-table--td" style="padding-right: 5px">
                                <vs-input class="w-full" v-model="itemParent.unit" disabled />
                            </td>
                            <td v-else class="td vs-table--td" style="padding-right: 5px">
                                <multiselect :allowEmpty="false" :options="itemParent.optionUnit"
                                    v-model="itemParent.unit" :select-label="''" :deselect-label="''" :placeholder="''"
                                    :label="'name'" :track-by="'name'" @input="(val) => {
                                        onChangeUnit(val, 0, 'parent');
                                    }" :disabled="disableParentUnit || selectedBom != null" />
                            </td>
                            <td class="td vs-table--td" style="padding-right: 10px">
                                <vs-input class="w-full" v-model="itemParent.qty_uom" disabled />
                            </td>
                            <td class="td vs-table--td align-top" style="padding: 5px">
                                <multiselect :allowEmpty="false" :options="itemParent.optionSupplier"
                                    v-model="itemParent.supplier" :select-label="''" :deselect-label="''"
                                    :placeholder="''" :label="'supplier_code'" :track-by="'supplier_code'"
                                    :disabled="isDisableSupplier" @input="(val) => {
                                        onChangeSupplier(val)
                                    }" />
                            </td>
                            <td class="td vs-table--td" style="padding-right: 10px">
                                <vs-input class="w-full" :value="formattedItemParentPrice" disabled />
                            </td>
                            <td class="td vs-table--td align-top" style="padding: 5px">
                                <vs-input class="w-full" type="text" v-model="itemParent.subTotalText"
                                    readonly></vs-input>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <h3><b>Child (SKU Source)</b></h3>
        <hr />
        <br />

        <div class="vx-row">
            <div class="vx-col sm:w-1/1 w-full mb-base m-10">
                <table width="100%" class="vs-table vs-table--tbody-table">
                    <thead class="vs-table--thead">
                        <tr>
                            <th width="40%">SKU Description</th>
                            <!-- <th width="30%">SKU Name</th> -->
                            <th width="5%">Qty</th>
                            <th width="10%">Unit</th>
                            <th width="10%">Qty UOM</th>
                            <th width="8%">MAP</th>
                            <th width="13%">Batch</th>
                            <th width="20%">Sub Total</th>
                            <!-- <th width="5%"></th> -->
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(source, index) in itemLines" :key="index" style="padding-bottom: 5px">
                            <td v-if="selectedBom && changedBom" class="td vs-table--td" style="padding: 5px">
                                <vs-input class="w-full" :value="`${source.sku_code} ${source.name}`" disabled />
                            </td>
                            <td v-else class="td vs-table--td" style="padding: 5px">
                                <multiselect :allowEmpty="false" :options="source.optionItem" :select-label="''"
                                    v-model="source.selected_items" :deselect-label="''" :label="'label'"
                                    :track-by="'label'" :placeholder="'Please type SKU Code or SKU Name'"
                                    @search-change="(val) => {
                                        handleSearchLines(val, index, 'child');
                                    }" @input="(val) => {
                                        onChangeSku(val, index, 'child');
                                    }" :disabled="selectedBom != null" />
                            </td>
                            <!-- <td class="td vs-table--td" style="padding: 5px">
                                <vs-input class="w-full" v-model="itemLines[index].name" disabled />
                            </td> -->
                            <td v-if="selectedBom && changedBom" class="td vs-table--td" style="padding-right: 10px">
                                <vs-input class="w-full" v-model="source.qty" disabled />
                            </td>
                            <td v-else class="td vs-table--td" style="padding-right: 10px">
                                <vs-input class="w-full" type="number" :min="1" v-model="source.qty" @input="(val) => {
                                    onChangeQty(val, index, 'child');
                                }" :disabled="selectedBom != null" />
                            </td>
                            <td v-if="selectedBom && changedBom" class="td vs-table--td" style="padding-right: 5px">
                                <vs-input class="w-full" v-model="source.unit" disabled />
                            </td>
                            <td v-else class="td vs-table--td" style="padding-right: 5px">
                                <multiselect :allowEmpty="false" :options="source.optionUnit" :select-label="''"
                                    v-model="source.unit" :deselect-label="''" :placeholder="''" :label="'name'"
                                    :track-by="'name'" @input="(val) => {
                                        onChangeUnit(val, index, 'child');
                                    }" :disabled="selectedBom != null" />
                                <!-- <vs-input class="w-full" v-model="itemLines[index].unit" disabled /> -->
                            </td>
                            <td class="td vs-table--td" style="padding-right: 5px">
                                <vs-input class="w-full" v-model="source.qty_uom" disabled />
                            </td>
                            <td class="td vs-table--td" style="padding-right: 5px">
                                <vs-input class="w-full" type="text" :value="formattedItemChildPrice(source.price)"
                                    disabled />
                                <!-- <vs-input class="w-full" :value="formattedItemChildPrice(itemLines[index].price)"
                                    disabled /> -->
                            </td>
                            <td class=" td vs-table--td" style="padding-right: 5px">
                                <multiselect :allowEmpty="false" :options="source.optionBatch" v-model="source.batch"
                                    :select-label="''" :deselect-label="''" :placeholder="''" :label="'batch'"
                                    :track-by="'batch'" />
                                <!-- <vs-input class="w-full" v-model="itemLines[index].batch" disabled /> -->
                            </td>
                            <td class="td vs-table--td align-top" style="padding: 5px">
                                <vs-input class="w-full" type="text" v-model="source.subTotalText" readonly
                                    :min="0"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="text-align: center">
                                <div class="vx-input-group flex">
                                    <vs-button v-if="itemLines.length > 1" size="small" color="danger"
                                        icon-pack="feather" icon="icon-trash" style="margin-right: 5px"
                                        title="Remove Row" @click.stop="removeRow(index)" />
                                    <!-- <vs-button v-if="index == itemLines.length - 1" size="small" color="success"
                                        icon-pack="feather" icon="icon-plus" title="Add Row"
                                        :disabled="!selectedPurpose || selectedPurpose.value == 0 ? true : false" /> -->
                                    <vs-button v-if="index == itemLines.length - 1" size="small" color="success"
                                        icon-pack="feather" icon="icon-plus" title="Add Row" @click.stop="addRow(index)"
                                        :disabled="!selectedPurpose || selectedPurpose.value == 0 ? true : false" />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table width="100%" class="vs-table vs-table--tbody-table">
                    <thead class="vs-table--thead">
                        <tr>
                            <th width="20%"></th>
                            <th width="20%"></th>
                            <th width="20%"></th>
                            <th width="10%"></th>
                            <th>TOTAL</th>
                            <th>{{ total }}</th>
                        </tr>
                    </thead>
                </table>
            </div>
        </div>
        <br>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/1 w-full">
                <div style="
                    position: absolute;
                    left: 50%;
                    -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                ">
                    <vs-button class="ml-4 mt-2" color="primary" v-on:click="SubmitForm()">Submit</vs-button>
                    <vs-button class="ml-2 mt-2" type="border" color="danger" v-on:click="handleBack()">Back</vs-button>
                </div>
            </div>
        </div>
    </vx-card>
</template>

<!-- NANTI MODIFIKASI MANAJEMEN FILE ATTACHMENT -->

<script>
import moment from "moment";
import Datepicker from "vuejs-datepicker";

export default {
    props: {
        title: {
            type: String,
        },
        action: {
            type: String,
        },
    },
    components: {
        Datepicker
    },
    async mounted() {
        try {
            this.$vs.loading();
            this.getBillOfMaterial();
            const warehousesData = await this.getWarehouse();
            this.optionWarehouse = warehousesData
            const workOrderData = await this.getData(this.$route.params.id);
            this.loadWorkOrderData(workOrderData)
        } catch (error) {
            console.log(error)
        } finally {
            this.$vs.loading.close();
        }
    },
    computed: {
        formattedItemParentPrice() {
            return new Intl.NumberFormat("en-US", {
                minimumFractionDigits: 7,
                maximumFractionDigits: 7,
            }).format(this.itemParent.price)
        },
        disabledDatesExecution() {
            return {
                to: moment(this.requestDate).toDate()
            }
        },
        disabledDatesRequest() {
            return {
                from: moment().toDate()
            }
        },
        setPage: {
            get() {
                return 1;
            },
            set(val) {
                this.handleChangePage(val);
            },
        },
    },
    watch: {
        // requestDate: function (val) {
        //     this.minExecutionDate = moment(val).add(1, "days").format("YYYY-MM-DD");
        //     this.executionDate = moment(val).add(1, "days").format("YYYY-MM-DD");
        // },
        // "itemParent.qty": function (val) {
        //     if (this.itemParent.qty > 0) {
        //         // if (val % 2 == 0) {
        //         this.itemLines = this.itemLines.map((el) => {
        //             return {
        //                 id: el.id,
        //                 item_unit_id: el.item_unit_id,
        //                 sku_code: el.sku_code,
        //                 name: el.name,
        //                 unit: el.unit,
        //                 qty_old: el.qty_old,
        //                 qty: el.qty,
        //                 batch: el.batch,
        //                 price: el.price,
        //                 qty_uom: el.qty_uom
        //             };
        //         });
        //         // } else {
        //         //     val = val - 1;
        //         //     this.itemLines = this.itemLines.map((el) => {
        //         //         return {
        //         //             id: el.id,
        //         //             item_unit_id: el.item_unit_id,
        //         //             sku_code: el.sku_code,
        //         //             name: el.name,
        //         //             unit: el.unit,
        //         //             qty_old: el.qty_old,
        //         //             qty: Math.ceil((val / this.itemParent.qty_old) * el.qty_old),
        //         //         };
        //         //     });
        //         //     //then add 1 to the first element
        //         //     this.itemLines[0].qty = this.itemLines[0].qty + 1;
        //         // }
        //     } else {
        //         this.itemLines = this.itemLines.map((el) => {
        //             return {
        //                 id: el.id,
        //                 item_unit_id: el.item_unit_id,
        //                 sku_code: el.sku_code,
        //                 name: el.name,
        //                 unit: el.unit,
        //                 qty_old: el.qty_old,
        //                 qty: 0,
        //             };
        //         });
        //     }
        // },
        "itemLines": {
            deep: true,
            async handler(newVal) {
                setTimeout(async () => {
                    if (this.selectedPurpose.value == 0 && !this.selectedBom && !this.changedBom) {
                        const itemLineItem = newVal[0]
                        this.itemParent.qty = itemLineItem.qty
                        if (itemLineItem.unit) {
                            const foundUnit = this.itemParent.optionUnit.find(unit => unit.name == newVal[0].unit.name)
                            if (foundUnit) {
                                this.itemParent.unit = this.itemParent.optionUnit.find(unit => unit.name == newVal[0].unit.name)
                                const data = await this.handleGetUnitUom(this.itemParent.selected_items.sku_code, this.itemParent.unit.unit_id);
                                const uomDataSource = await this.handleGetUnitUom(this.itemLines[0].selected_items.sku_code, this.itemLines[0].unit.unit_id)

                                this.itemLines[0].qty_uom = uomDataSource
                                if (data != uomDataSource) {
                                    this.$vs.notify({
                                        title: "Error",
                                        text: "Qty UOM SKU result and source not same",
                                        color: "danger",
                                        position: "top-right",
                                        iconPack: "feather",
                                        icon: "icon-check",
                                    });

                                    this.itemParent.unit = null
                                    this.itemParent.qty_uom = 0

                                    return
                                }

                                if (this.itemParent.selected_items.source_value) {
                                    this.itemParent.price = this.itemParent.supplier.price
                                }

                                this.itemParent.qty_uom = data
                                const currUOM = data
                                const currMAP = this.itemParent.price
                                const currQTY = this.itemParent.qty
                                const subTotal = currUOM * currMAP * currQTY

                                const subTotalText = new Intl.NumberFormat("en-US", {
                                    minimumFractionDigits: 7,
                                    maximumFractionDigits: 7,
                                }).format(subTotal)

                                this.itemParent.subTotal = subTotal
                                this.itemParent.subTotalText = subTotalText


                                const itemLineCurrUOM = uomDataSource
                                const itemLineMAP = this.itemLines[0].price
                                const itemLineQTY = this.itemLines[0].qty
                                const itemLineSubtotal = itemLineCurrUOM * itemLineMAP * itemLineQTY
                                const subTotalLineText = new Intl.NumberFormat("en-US", {
                                    minimumFractionDigits: 7,
                                    maximumFractionDigits: 7,
                                }).format(itemLineSubtotal)

                                this.itemLines[0].subTotal = itemLineSubtotal
                                this.itemLines[0].subTotalText = subTotalLineText

                                this.sumTotal()
                            } else {
                                this.$vs.notify({
                                    title: "Error",
                                    text: "Parent unit not found",
                                    color: "danger",
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-check",
                                });

                                this.$vs.loading();
                                setTimeout(() => {
                                    itemLineItem.unit = null
                                    this.itemLines[0].qty_uom = 0
                                    this.itemLines[0].subTotal = 0
                                    this.itemLines[0].subTotalText = "0"

                                    this.sumTotal()
                                    this.$vs.loading.close();
                                }, 1000);

                                return
                            }
                        } else {
                            // const currUOM = this.itemParent.qty_uom
                            // const currMAP = this.itemParent.price
                            // const currQTY = this.itemParent.qty

                            // const subTotal = currUOM * currMAP * currQTY
                            // this.itemParent.subTotal = subTotal
                            // const subTotalText = new Intl.NumberFormat("en-US", {
                            //     minimumFractionDigits: 7,
                            //     maximumFractionDigits: 7,
                            // }).format(subTotal)

                            // this.itemParent.subTotal = subTotal
                            // this.itemParent.subTotalText = subTotalText
                        }
                    }
                }, 200);
            }
        }
    },
    data: () => ({
        isDisableSupplier: true,
        optionPurpose: [
            {
                value: 0,
                name: "SKU-ID Change",
            },
            {
                value: 1,
                name: "Repackaging",
            },
        ],
        disableParentQty: false,
        disableParentUnit: false,
        itemLines: [
            {
                id: null,
                item_unit_id: null,
                sku_code: "",
                name: "",
                unit: "",
                qty: 0,
                qty_old: 0,
                qty_uom: 0,
                price: 0,
                batch: null,
                optionItem: [],
                optionUnit: [],
                optionBatch: [],
                selected_items: null,
                subTotal: 0,
                subTotalText: "0",
            },
        ],
        itemParent: {
            optionSupplier: [],
            supplier: null,
            optionItem: [],
            optionUnit: [],
            item_unit_id: null,
            sku_code: "",
            name: "",
            unit: "",
            qty: 0,
            qty_old: 0,
            price: 0,
            qty_uom: 0,
            batch: null,
            selected_items: null,
            optionBatch: [],
            subTotal: 0,
            sourceValue: null,
            subTotalText: "0"
        },
        optionBom: [],
        selectedBom: null,
        changedBom: false,
        selectedPurpose: null,
        selectedWarehouse: null,
        optionWarehouse: [],
        selectedWarehouseArea: null,
        optionWarehouseArea: [],
        requestDate: moment().format("YYYY-MM-DD"),
        minExecutionDate: moment().add(1, "days").format("YYYY-MM-DD"),
        executionDate: moment().add(1, "days").format("YYYY-MM-DD"),
        start: 1,
        end: 0,
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        code: "",
        description: "",
        note: "",
        // attachment: "",
        fileAttachment: [],
        newFileAttachment: [],
        IDAttachment: [],
        now: moment().add(0, "days").format("YYYY-MM-DD"),
        IDs: null,
        isDisabled: true,
        allowedExtension: ["xlsx", "xls", "jpg", "png", "pdf", "mp4", "avi", "mkv", "3gp", "m4v", "wmv", "mov"],
        workOrderStatus: 1,
    }),
    methods: {
        async onChangeSku(val, index, type) {
            if (type == "child") {
                const data = await this.getUnitOptions(val.sku_code, index, type);
                this.itemLines[index].optionUnit = [];
                this.itemLines[index].unit = null;
                this.itemLines[index].qty_uom = 0;
                this.itemLines[index].qty_old = 0;
                this.itemLines[index].sku_code = val.sku_code;
                this.itemLines[index].item_name = val.item_name;
                this.itemLines[index].label = val.label;
                this.itemLines[index].price = val.price;
                this.itemLines[index].subTotal = 0
                this.itemLines[index].subTotalText = "0"
                this.itemLines[index].batch = null
                this.itemLines[index].optionBatch = [];

                this.sumTotal()

                this.itemLines[index].optionUnit = data.option_units
            } else {
                const data = await this.getUnitOptions(val.sku_code, index, type);
                this.itemParent.optionUnit = [];
                this.itemParent.unit = null;
                this.itemParent.qty_uom = 0;
                this.itemParent.qty_old = 0;
                this.itemParent.sku_code = val.sku_code;
                this.itemParent.item_name = val.item_name;
                this.itemParent.label = val.label;
                this.itemParent.price = val.source_value ? 0 : val.price
                this.itemParent.sourceValue = val.source_value
                this.isDisableSupplier = val.source_value ? false : true
                this.itemParent.subTotal = 0
                this.itemParent.subTotalText = "0"
                this.itemParent.supplier = null;
                this.itemParent.optionSupplier = []

                if (this.selectedPurpose.value == 0) {
                    this.itemLines.forEach(item => {
                        item.unit = null
                        item.qty_uom = 0
                        item.subTotal = 0
                        item.subTotalText = "0"
                        item.batch = null
                        item.optionBatch = []
                    })
                }
                this.sumTotal()


                if (val.source_value) {
                    const { WarehouseID } = this.selectedWarehouseArea
                    const data = await this.getSupplier(val.sku_code, WarehouseID)
                    this.itemParent.optionSupplier = data.records
                }

                this.itemParent.optionUnit = data.option_units
            }
        },
        async onChangeUnit(val, index, type) {
            try {
                this.$vs.loading();
                const { unit_id, id } = val
                const skuCode = type == "child" ? this.itemLines[index].sku_code : this.itemParent.sku_code
                const uomData = await this.handleGetUnitUom(skuCode, unit_id);

                if (type == "child") {
                    const batchData = await this.getBatch(id, this.itemLines[index].sku_code)
                    const currMAP = this.itemLines[index].price
                    const currQTY = this.itemLines[index].qty
                    this.itemLines[index].qty_uom = uomData;
                    this.itemLines[index].optionBatch = batchData || []

                    const subTotal = uomData * currMAP * currQTY
                    this.itemLines[index].subTotal = subTotal

                    const subTotalText = new Intl.NumberFormat("en-US", {
                        minimumFractionDigits: 7,
                        maximumFractionDigits: 7,
                    }).format(subTotal)

                    this.itemLines[index].subTotalText = subTotalText

                    this.sumTotal()
                } else {
                    this.itemParent.qty_uom = uomData
                    const currMAP = this.itemParent.price
                    const currQTY = this.itemParent.qty
                    const subTotal = uomData * currMAP * currQTY
                    this.itemParent.optionBatch = []
                    this.itemParent.subTotal = subTotal

                    const subTotalText = new Intl.NumberFormat("en-US", {
                        minimumFractionDigits: 7,
                        maximumFractionDigits: 7,
                    }).format(subTotal)


                    this.itemParent.subTotalText = subTotalText
                }
            } catch (error) {
                console.log(error)
            } finally {
                this.$vs.loading.close();
            }
        },
        onChangeQty(val, index, type) {
            if (val < 1) {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: "Qty must be greater than zero",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });

                if (type == "child") {
                    this.itemLines[index].qty = 0;
                } else {
                    this.itemParent.qty = 0;
                }
            }

            if (type == "child") {
                const currMAP = this.itemLines[index].price
                const currQTY = this.itemLines[index].qty
                const currUOM = this.itemLines[index].qty_uom
                const subTotal = currUOM * currMAP * currQTY
                this.itemLines[index].subTotal = subTotal

                const subTotalText = new Intl.NumberFormat("en-US", {
                    minimumFractionDigits: 7,
                    maximumFractionDigits: 7,
                }).format(subTotal)

                this.itemLines[index].subTotalText = subTotalText

                this.sumTotal()
            } else {
                const currUOM = this.itemParent.qty_uom
                const currMAP = this.itemParent.price
                const currQTY = this.itemParent.qty
                const subTotal = currUOM * currMAP * currQTY
                this.itemParent.subTotal = subTotal

                const subTotalText = new Intl.NumberFormat("en-US", {
                    minimumFractionDigits: 7,
                    maximumFractionDigits: 7,
                }).format(subTotal)

                this.itemParent.subTotalText = subTotalText
            }

        },
        onChangeSupplier(val) {
            this.itemParent.price = val.price

            const currUOM = this.itemParent.qty_uom
            const currMAP = this.itemParent.price
            const currQTY = this.itemParent.qty
            const subTotal = currUOM * currMAP * currQTY

            this.itemParent.subTotal = subTotal

            const subTotalText = new Intl.NumberFormat("en-US", {
                minimumFractionDigits: 7,
                maximumFractionDigits: 7,
            }).format(subTotal)

            this.itemParent.subTotalText = subTotalText
        },
        onChangePurpose(val) {
            const { value } = val

            this.resetItemParent()
            this.resetItemLines()
            this.selectedWarehouse = null
            this.selectedWarehouseArea = null

            this.selectedWarehouse = null

            this.optionWarehouseArea = []
            this.selectedWarehouseArea = null

            if (value == 0) {
                //SKU ID CHANGE
                this.disableParentQty = true
                this.disableParentUnit = true
            } else {
                // REPACAKING
                this.disableParentQty = false
                this.disableParentUnit = false
            }

            this.sumTotal()

        },
        async onChangeWarehouse(warehouseID) {
            const warehouseAreaData = await this.getWarehouseArea(warehouseID)

            this.optionWarehouseArea = warehouseAreaData
        },
        async onChangeWarehouseArea() {
            const { value } = this.selectedPurpose
            const { WarehouseID, WarehouseAreaID } = this.selectedWarehouseArea
            const parentMaterialData = await this.getMaterialWo("", "parent", value, WarehouseID, WarehouseAreaID);
            const childMaterialData = await this.getMaterialWo("", "child", value, WarehouseID, WarehouseAreaID);

            this.itemParent.optionItem = parentMaterialData.records
            this.itemLines[0].optionItem = childMaterialData.records

            if (this.selectedBom && this.changedBom) {
                const { SkuCode, ItemBillOfMaterialLines } = this.selectedBom
                const priceType = "parent"
                const { value: purposeType } = this.selectedPurpose
                const { WarehouseID, WarehouseAreaID } = this.selectedWarehouseArea

                const data = await this.getPrice(SkuCode, priceType, purposeType, WarehouseID, WarehouseAreaID)
                this.itemParent.price = data.records.length > 0 ? data.records[0].price : 0

                if (data.records[0].source_value) {
                    const { WarehouseID } = this.selectedWarehouseArea
                    const data = await this.getSupplier(SkuCode, WarehouseID)
                    this.isDisableSupplier = false

                    console.log(data)
                    this.itemParent.optionSupplier = data.records
                } else {
                    this.isDisableSupplier = true
                    const currUOM = this.itemParent.qty_uom
                    const currMAP = this.itemParent.price
                    const currQTY = this.itemParent.qty
                    const subTotal = currUOM * currMAP * currQTY
                    this.itemParent.subTotal = subTotal

                    const subTotalText = new Intl.NumberFormat("en-US", {
                        minimumFractionDigits: 7,
                        maximumFractionDigits: 7,
                    }).format(subTotal)

                    this.itemParent.subTotalText = subTotalText
                }

                for (const [index, item] of ItemBillOfMaterialLines.entries()) {
                    console.log(item)
                    const skuCode = item.SkuCode
                    const itemUnitId = item.ItemUnitID
                    const priceType = "child"
                    const data = await this.getPrice(skuCode, priceType, purposeType, WarehouseID, WarehouseAreaID)
                    const price = data.records.length > 0 ? data.records[0].price : 0
                    const batchData = await this.getBatch(itemUnitId, skuCode)

                    this.itemLines[index].price = price
                    this.itemLines[index].optionBatch = batchData

                    const currUOM = this.itemLines[index].qty_uom
                    const currMAP = this.itemLines[index].price
                    const currQTY = this.itemLines[index].qty
                    const subTotal = currUOM * currMAP * currQTY
                    this.itemLines[index].subTotal = subTotal

                    const subTotalText = new Intl.NumberFormat("en-US", {
                        minimumFractionDigits: 7,
                        maximumFractionDigits: 7,
                    }).format(subTotal)

                    this.itemLines[index].subTotalText = subTotalText
                }

                const total = this.itemLines.reduce((a, b) => a + b.subTotal, 0)
                const totalText = new Intl.NumberFormat("en-US", {
                    minimumFractionDigits: 7,
                    maximumFractionDigits: 7,
                }).format(total)
                this.total = totalText
            }
        },
        resetItemLines() {
            this.itemLines = [
                {
                    id: null,
                    item_unit_id: null,
                    sku_code: "",
                    name: "",
                    unit: "",
                    qty: 0,
                    qty_old: 0,
                    qty_uom: 0,
                    price: 0,
                    batch: null,
                    optionItem: [],
                    optionUnit: [],
                    optionBatch: [],
                    selected_items: null,
                    subTotal: 0,
                    subTotalText: "0",
                },
            ];
        },
        resetItemParent() {
            this.itemParent = {
                optionSupplier: [],
                supplier: null,
                optionItem: [],
                optionUnit: [],
                item_unit_id: null,
                sku_code: "",
                name: "",
                unit: "",
                qty: 0,
                qty_old: 0,
                price: 0,
                qty_uom: 0,
                batch: null,
                selected_items: null,
                sourceValue: null,
                optionBatch: [],
                subTotal: 0,
                subTotalText: "0"
            }
        },
        getPrice(search, type, purposeType, warehouseID, WarehouseAreaId) {
            if (type === 'child') {
                purposeType = 0
            }
            this.$vs.loading();
            return new Promise((resolve, reject) => {
                this.$http
                    .get("api/wms/v1/work-order/item-material", {
                        params: {
                            type: type,
                            search: search,
                            purposeType,
                            warehouseID,
                            WarehouseAreaId,
                        },
                    })
                    .then((res) => {
                        resolve(res.data)
                        this.$vs.loading.close();
                    })
                    .catch((error) => {
                        reject(error)
                        this.$vs.loading.close();
                    })
            })

        },
        async loadWorkOrderData(data) {
            const warehouseAreaData = await this.getWarehouseArea(data.warehouse_id)
            this.optionWarehouseArea = warehouseAreaData

            this.selectedBom = this.optionBom.find((bom) => bom.Code == data.item_bom_code)

            this.IDs = data.id;
            this.code = data.code;
            this.description = data.description;
            this.note = data.note;
            this.requestDate = data.request_date;
            this.executionDate = data.execution_date;
            this.workOrderStatus = data.status

            if (data.purpose_type == 0) {
                this.disableParentQty = true
                this.disableParentUnit = true
            }

            this.selectedPurpose = this.optionPurpose.find(purpose => purpose.value == data.purpose_type)
            this.selectedWarehouse = this.optionWarehouse.find(warehouse => warehouse.WarehouseID == data.warehouse_id)
            this.selectedWarehouseArea = this.optionWarehouseArea.find(warehouseArea => warehouseArea.WarehouseAreaID == data.warehouse_area_id)

            this.loadMaterialResult(data)
            this.loadMaterialSource(data)

            this.fileAttachment = data.attachment_data ? data.attachment_data.filter(attachment => attachment.deleted == "").map(attachment => ({
                id: attachment.id,
                NameFile: attachment.file_name,
                PathFile: attachment.file_url
            })) : []
        },
        async loadMaterialResult(data) {
            const parentItemData = data.item_parent
            const materialResultData = await this.getMaterialWo(parentItemData.sku_code, "parent", this.selectedPurpose.value, this.selectedWarehouse.WarehouseID, this.selectedWarehouseArea.WarehouseAreaID)

            const supplierData = await this.getSupplier(parentItemData.sku_code, this.selectedWarehouse.WarehouseID)
            this.itemParent.optionSupplier = supplierData.records
            if (this.itemParent.optionSupplier.length) {
                this.isDisableSupplier = false
                this.itemParent.supplier = this.itemParent.optionSupplier.find(item => item.supplier_code === data.supplier_code)
            }

            this.itemParent.optionItem = materialResultData.records
            const unitOptions = await this.getUnitOptions(parentItemData.sku_code)
            this.itemParent.optionUnit = unitOptions.option_units
            this.itemParent.unit = this.itemParent.optionUnit.find(unit => unit.id == parentItemData.item_unit_id)
            this.itemParent.qty_uom = await this.handleGetUnitUom(parentItemData.sku_code, this.itemParent.unit.unit_id)

            const selectedItem = this.itemParent.optionItem.filter(item => item.sku_code == parentItemData.sku_code).pop()
            this.itemParent.selected_items = selectedItem
            this.itemParent.qty = parentItemData.quantity

            this.itemParent.sourceValue = selectedItem.source_value
            this.itemParent.price = selectedItem.source_value ? this.itemParent.supplier.price : selectedItem.price

            if (!selectedItem.source_value) {
                this.isDisableSupplier = true
            }

            const subTotal = this.itemParent.qty * this.itemParent.qty_uom * this.itemParent.price
            this.itemParent.subTotal = subTotal
            const subTotalText = new Intl.NumberFormat("en-US", {
                minimumFractionDigits: 7,
                maximumFractionDigits: 7,
            }).format(subTotal)
            this.itemParent.subTotalText = subTotalText
        },
        async loadMaterialSource(data) {
            const sourceMaterial = await Promise.all(data.work_order_lines.map(async (sourceMaterial) => {
                const materialSourceData = await this.getMaterialWo(sourceMaterial.sku_code, "child", this.selectedPurpose.value, this.selectedWarehouse.WarehouseID, this.selectedWarehouseArea.WarehouseAreaID)

                const selectedItem = materialSourceData.records.find(material => material.sku_code == sourceMaterial.sku_code)
                const { option_units } = await this.getUnitOptions(sourceMaterial.sku_code)
                const unit = option_units.find(unit => unit.id == sourceMaterial.item_unit_id)
                const qty_uom = await this.handleGetUnitUom(sourceMaterial.sku_code, unit.unit_id)
                const optionBatch = await this.getBatch(sourceMaterial.item_unit_id, sourceMaterial.sku_code)
                const batch = optionBatch.find(batch => batch.batch == sourceMaterial.batch)

                const subTotal = qty_uom * sourceMaterial.price * sourceMaterial.quantity
                const subTotalText = new Intl.NumberFormat("en-US", {
                    minimumFractionDigits: 7,
                    maximumFractionDigits: 7,
                }).format(subTotal)

                return {
                    sku_code: selectedItem.sku_code,
                    selectedItem,
                    optionItem: materialSourceData.records,
                    qty: sourceMaterial.quantity,
                    qty_uom,
                    optionUnit: option_units,
                    price: sourceMaterial.price,
                    optionBatch,
                    batch,
                    unit,
                    subTotal,
                    subTotalText
                }
            }))

            this.itemLines = sourceMaterial.map(material => ({
                sku_code: material.sku_code,
                selected_items: material.selectedItem,
                optionItem: material.optionItem,
                qty: material.qty,
                optionUnit: material.optionUnit,
                qty_uom: material.qty_uom,
                price: material.price,
                optionBatch: material.optionBatch,
                batch: material.batch,
                unit: material.unit,
                subTotal: material.subTotal,
                subTotalText: material.subTotalText
            }))

            this.sumTotal()
        },
        async getMaterialWo(search, type, purposeType, warehouseID, WarehouseAreaID) {
            try {
                this.$vs.loading();

                let skuType = "Result"
                if (type === 'child') {
                    purposeType = 0
                    skuType = "Source"
                }

                const response = await this.$http
                    .get("api/wms/v1/work-order/item-material", {
                        params: {
                            type,
                            search,
                            purposeType,
                            warehouseID,
                            WarehouseAreaID,
                            skuType
                        },
                    })

                return response.data
            } catch (error) {
                console.log(error)
            } finally {
                this.$vs.loading.close();
            }

            // .then((resp) => {
            //     if (type == "child") {
            //         if (resp.code == 200) {
            //             if (resp.data.records) {
            //                 this.itemLines[index].optionItem = resp.data.records;
            //             } else {
            //                 this.itemLines[index].optionItem = [];
            //             }
            //         } else {
            //             this.itemLines[index].optionItem = [];
            //         }
            //     } else {
            //         if (resp.code == 200) {
            //             if (resp.data.records) {
            //                 this.itemParent.optionItem = resp.data.records;
            //             } else {
            //                 this.itemParent.optionItem = [];
            //             }
            //         } else {
            //             this.itemParent.optionItem = [];
            //         }
            //     }
            // })
            // .catch((error) => {
            //     console.log(error);
            // });
        },
        async addRow() {
            const { value: purpose } = this.selectedPurpose

            const { WarehouseID, WarehouseAreaID } = this.selectedWarehouseArea
            const materialData = await this.getMaterialWo("", "child", purpose, WarehouseID, WarehouseAreaID);
            this.itemLines.push({
                id: null,
                item_unit_id: null,
                sku_code: "",
                name: "",
                unit: "",
                qty: 0,
                qty_old: 0,
                qty_uom: 0,
                price: 0,
                batch: null,
                optionItem: materialData.records,
                optionUnit: [],
                optionBatch: [],
                selected_items: null,
                subTotal: 0,
                subTotalText: "0",
            });

        },
        removeRow(index) {
            this.itemLines.splice(index, 1);

            this.sumTotal()
        },
        sumTotal() {
            const arrOfSubTotal = this.itemLines.map(item => item.qty * item.qty_uom * item.price)
            const total = arrOfSubTotal.reduce((a, b) => a + b, 0)
            const totalText = new Intl.NumberFormat("en-US", {
                minimumFractionDigits: 7,
                maximumFractionDigits: 7,
            }).format(total)
            this.total = totalText
        },
        handleBack() {
            this.$router.back();
        },
        SubmitForm() {
            if (!this.description) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please input Work Order Description",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check",
                });
                return
            }

            if (!this.selectedPurpose) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select purpose work order",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check",
                });
                return false
            }



            // if (!this.selectedBom || !this.optionBom.length) {
            //     this.$vs.notify({
            //         title: "Error",
            //         text: "Please select Bill of Material",
            //         color: "danger",
            //         position: "top-right",
            //         iconPack: "feather",
            //         icon: "icon-check",
            //     });
            //     return;
            // }
            if (!this.selectedWarehouse) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select Warehouse",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check",
                });
                return;
            }

            if (!this.selectedWarehouseArea) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select Warehouse Area",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check",
                });
                return;
            }

            if (this.itemParent.selected_items.sku_code == "") {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select item parent first",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check",
                });
                return;
            }

            if (this.itemParent.qty < 1 || parseFloat(this.itemParent.qty) <= 0) {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: "Parent Quantity must greater than 0",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return false;
            }

            if (!this.itemParent.unit) {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: "Please select unit result",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return false;
            }

            if (!this.itemParent.optionSupplier.length && !this.itemParent.price) {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: "The MAP or DBP price must not be zero.",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return false;
            }

            if (this.itemParent.optionSupplier.length && !this.itemParent.supplier && this.itemParent.sourceValue) {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: "Please select supplier sku result",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return false;
            }

            if (this.itemLines.some(item => item.sku_code == "")) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select Item Line first",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check",
                });
                return;
            }

            if (this.itemLines.some(item => item.qty < 1)) {
                this.$vs.notify({
                    title: "Error",
                    text: 'Quantity must be greater than zero',
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check",
                });
                return;
            }

            if (this.itemLines.some(item => item.sku_code == this.itemParent.sku_code)) {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: "The SKU code of the parent and child items must not be the same.",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return false;
            }

            if (this.itemLines.length == 0) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select Item Line first",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check",
                });
                return true;
            }

            if (this.itemLines.some(item => item.unit == null)) {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: "Please select item child unit",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return false;
            }


            if (this.itemLines.some(item => item.batch == null)) {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: "Please select item child batch",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return false;
            }

            if (this.fileAttachment.some(a => !this.allowedExtension.includes(a.NameFile.split(".").at(-1).toLowerCase()))) {
                this.$vs.notify({
                    title: "Error",
                    text: "Invalid attachments extention",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check",
                });
                return true;
            }

            const ibomid = this.selectedBom ? this.selectedBom.id : null
            const ibomCode = this.selectedBom ? this.selectedBom.Code : null

            let payload = {}

            if (this.selectedBom) {
                payload = {
                    id: this.IDs,
                    note: this.note,
                    description: this.description,
                    item_unit_id: Number(this.itemParent.item_unit_id),
                    sku_code: this.itemParent.selected_items.sku_code,
                    name: this.itemParent.selected_items.item_name,
                    unit: this.itemParent.unit.name,
                    quantity: Number(this.itemParent.qty),
                    default_quantity: Number(this.itemParent.qty_old),
                    price: Number(this.itemParent.price),
                    warehouse_id: this.selectedWarehouse.WarehouseID,
                    warehouse_name: this.selectedWarehouse.WarehouseName,
                    item_bill_of_material_id: ibomid,
                    item_bom_code: ibomCode,
                    warehouse_area_id: this.selectedWarehouseArea.WarehouseAreaID,
                    qty_uom: this.itemParent.qty_uom,
                    // batch: this.itemParent.batch.batch,
                    request_date: this.requestDate,
                    execution_date: this.executionDate,
                    purpose_type: this.selectedPurpose.value,
                    supplier_code: this.itemParent.supplier ? this.itemParent.supplier.supplier_code : null,
                    item_sku: this.itemLines.map((el) => {
                        return {
                            item_unit_id: Number(el.item_unit_id),
                            sku_code: el.sku_code,
                            sku_name: el.selected_items.item_name,
                            unit_name: el.unit.name,
                            qty: Number(el.qty),
                            quantity_real: Number(el.qty_old),
                            qty_uom: el.qty_uom,
                            price: Number(el.price),
                            batch: el.batch.batch
                        };
                    }),
                    status: this.workOrderStatus,
                }
            } else {
                payload = {
                    id: this.IDs,
                    note: this.note,
                    description: this.description,
                    item_unit_id: Number(this.itemParent.unit.id),
                    sku_code: this.itemParent.selected_items.sku_code,
                    name: this.itemParent.selected_items.item_name,
                    unit: this.itemParent.unit.name,
                    quantity: Number(this.itemParent.qty),
                    default_quantity: Number(this.itemParent.qty_old),
                    price: Number(this.itemParent.price),
                    warehouse_id: this.selectedWarehouse.WarehouseID,
                    warehouse_name: this.selectedWarehouse.WarehouseName,
                    item_bill_of_material_id: ibomid,
                    item_bom_code: ibomCode,
                    warehouse_area_id: this.selectedWarehouseArea.WarehouseAreaID,
                    qty_uom: this.itemParent.qty_uom,
                    // batch: this.itemParent.batch.batch,
                    request_date: this.requestDate,
                    execution_date: this.executionDate,
                    purpose_type: this.selectedPurpose.value,
                    supplier_code: this.itemParent.supplier ? this.itemParent.supplier.supplier_code : null,
                    item_sku: this.itemLines.map((el) => {
                        return {
                            item_unit_id: Number(el.unit.id),
                            sku_code: el.sku_code,
                            sku_name: el.selected_items.item_name,
                            unit_name: el.unit.name,
                            qty: Number(el.qty),
                            quantity_real: Number(el.qty_old),
                            qty_uom: el.qty_uom,
                            price: Number(el.price),
                            batch: el.batch.batch
                        };
                    }),
                    status: this.workOrderStatus,
                }
            }

            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Confirm`,
                text: "Are you sure to update this data?",
                accept: () => {
                    this.$vs.loading();
                    this.$http
                        .post("api/wms/v1/work-order/update", payload)
                        .then((resp) => {
                            // console.log(resp);
                            if (resp.code == 200) {
                                this.$vs.notify({
                                    title: "Success",
                                    text: "Work Order has been updated",
                                    color: "success",
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-check",
                                });
                                this.IDs = resp.data;
                                if (this.newFileAttachment.length) {
                                    this.handleSubmitAttachment();
                                } else {
                                    this.handleBack()
                                }
                            } else {
                                this.attachment = [];
                                this.$vs.notify({
                                    title: "Error",
                                    text: resp.message,
                                    color: "danger",
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-check",
                                });
                            }

                            this.$vs.loading.close();
                        })
                        .catch((error) => {
                            this.$vs.loading.close();
                            console.log(error);
                        });
                }
            });
        },
        formattedItemChildPrice(price) {
            return new Intl.NumberFormat("en-US", {
                minimumFractionDigits: 7,
                maximumFractionDigits: 7,
            }).format(price)
        },
        mappingAttachment() {
            this.adjustment.Attachment.map((v) => {
                this.fileAttachment.push({
                    ListID: v.ListID,
                    NameFile: v.NameFile,
                    PathFile: v.PathFile,
                });
            });
        },
        handleAttachment() {
            if (this.$refs.file.files.length > 0) {
                this.file = this.$refs.file.files;
                // this.fileAttachment = [];
                for (let i = 0; i < this.$refs.file.files.length; i++) {
                    // this.uploadData(i);
                    this.fileAttachment.push({
                        NameFile: this.file[i].name,
                        File: this.file[i],
                    });
                    this.newFileAttachment.push({
                        NameFile: this.file[i].name,
                        File: this.file[i],
                    });
                }
                // this.handleSubmitAttachment();
                document.querySelector("#fileInput").value = "";
            } else {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select file to import.",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check",
                });
            }
        },
        handleShowAttachment(file) {
            let objectURL;
            // console.log(file)
            objectURL = file.PathFile ? file.PathFile : URL.createObjectURL(file.File);

            // link.download = file.name; // this name is used when the user downloads the file
            var href = objectURL;
            window.open(href, "_blank").focus();
        },
        handleDeleteAttachment(data, index) {
            this.$vs.loading();
            this.$http
                .delete("api/wms/v1/work-order/delete-attachment", {
                    params: {
                        id: data,
                    },
                })
                .then((resp) => {
                    if (resp.code == 200) {
                        this.$vs.notify({
                            title: "Success",
                            text: "Data deleted successfully",
                            color: "success",
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-check",
                        });
                        this.fileAttachment = this.fileAttachment.filter((r, i) => {
                            return i != index;
                        });
                        this.IDAttachment = this.IDAttachment.filter((r, i) => {
                            return r != data.id;
                        });
                    }
                    this.$vs.loading.close();
                })
                .catch((error) => {
                    this.$vs.loading.close();
                    console.log(error);
                });
        },
        handleSubmitAttachment() {
            this.$vs.loading();
            if (this.newFileAttachment.length > 0) {
                const formData = new FormData();
                for (let index = 0; index < this.newFileAttachment.length; index++) {
                    formData.append("attachments[]", this.newFileAttachment[index].File);
                }
                formData.append("work_order_id", this.IDs);

                this.$http
                    .post("api/wms/v1/work-order/create-attachment", formData)
                    .then((resp) => {
                        if (resp.code == 200) {
                            // for (
                            //     let index = 0;
                            //     index < resp.data.attachment_id.length;
                            //     index++
                            // ) {
                            //     this.IDAttachment.push(resp.data.attachment_id[index]);
                            // }
                            // this.$vs.notify({
                            //     color: "success",
                            //     title: "Success",
                            //     text: resp.message,
                            //     position: "top-right",
                            //     iconPack: "feather",
                            //     icon: "icon-check-circle",
                            // });
                            // this.newFileAttachment = [];
                            this.handleBack();
                            this.$vs.loading.close();
                        } else {
                            this.$vs.notify({
                                color: "danger",
                                title: "Failed",
                                text: resp.message,
                                position: "top-right",
                                iconPack: "feather",
                                icon: "icon-x-circle",
                            });
                            this.$vs.loading.close();
                        }
                    })
                    .catch((error) => {
                        this.$vs.loading.close();
                        console.log(error);
                    });
            }
        },
        async getSupplier(skuCode, warehouseID) {
            this.$vs.loading();
            try {
                const response = await this.$http
                    .get("api/wms/v1/work-order/supplier-price", {
                        params: {
                            sku_code: skuCode,
                            warehouse_id: warehouseID
                        },
                    })
                return response.data
            } catch (error) {
                console.log(error)
            } finally {
                this.$vs.loading.close();
            }
        },
        getBillOfMaterial() {
            this.$vs.loading();
            this.$http
                .get("api/wms/v1/work-order/item-bill-of-material", {
                    params: {
                        length: null,
                        order: "id",
                        sort: "asc",
                    },
                })
                .then((resp) => {
                    if (resp.code == 200) {
                        if (resp.data.records) {
                            this.optionBom = resp.data.records;
                            if (this.optionBom.length > 0) {
                                // this.selectedBom = this.optionBom[0];
                            } else {
                                // this.optionBom = [];
                                // this.selectedBom = {};
                            }
                        } else {
                            this.optionBom = [];
                            this.selectedBom = {};
                        }
                        this.$vs.loading.close();
                    } else {
                        this.$vs.loading.close();
                    }
                })
                .catch((error) => {
                    this.$vs.loading.close();
                    console.log(error);
                });
        },
        async getWarehouse() {
            const response = await this.$http
                .get("api/wms/v1/work-order/warehouse-by-user", {
                    params: {
                        length: null,
                        order: "name",
                        sort: "asc",
                    },
                })
            return response.data
            // this.$http
            //     .get("api/wms/v1/work-order/warehouse-by-user", {
            //         params: {
            //             length: null,
            //             order: "name",
            //             sort: "asc",
            //         },
            //     })
            //     .then((resp) => {
            //         if (resp.code == 200) {
            //             if (resp.data.records) {
            //                 this.optionWarehouse = resp.data.records;
            //                 if (this.optionWarehouse.length > 0) {
            //                     // this.selectedWarehouse = this.optionWarehouse[0];
            //                 } else {
            //                     this.optionWarehouse = [];
            //                     this.selectedWarehouse = {};
            //                 }
            //             } else {
            //                 this.optionWarehouse = [];
            //                 this.selectedWarehouse = {};
            //             }
            //             this.$vs.loading.close();
            //         } else {
            //             this.$vs.loading.close();
            //         }
            //     })
            //     .catch((error) => {
            //         this.$vs.loading.close();
            //         console.log(error);
            //     });
        },
        async getWarehouseArea(warehouseID) {
            try {
                this.$vs.loading();

                const response = await this.$http
                    .get("api/wms/v1/work-order/warehouse-area-by-warehouse", {
                        params: {
                            order: "name",
                            sort: "asc",
                            warehouseID
                        },
                    })
                return response.data

            } catch (error) {
                console.log(error)
            } finally {
                this.$vs.loading.close();

            }
        },
        async getUnitOptions(skuCode) {
            const response = await this.$http
                .get("api/wms/v1/item-bill-of-material/unit-options", {
                    params: {
                        sku_code: skuCode,
                    },
                })
            // .then((resp) => {
            //     if (type == "child") {
            //         if (resp.code == 200) {
            //             this.itemLines[index].optionUnit = resp.data.option_units;
            //         } else {
            //             this.itemLines[index].optionUnit = [];
            //         }
            //     } else {
            //         if (resp.code == 200) {
            //             this.itemParent.optionUnit = resp.data.option_units;
            //         } else {
            //             this.itemParent.optionUnit = [];
            //         }
            //     }
            // })
            // .catch((error) => {
            //     console.log(error);
            // });
            return response.data
        },
        async handleGetUnitUom(skuCode, unit) {
            const response = await this.$http
                .get("api/wms/v1/item-bill-of-material/unit-uom", {
                    params: {
                        sku_code: skuCode,
                        unit: unit,
                    },
                })
            return response.data
        },
        async getBatch(itemUnitId, skuCode) {
            const response = await this.$http
                .get("api/wms/v1/work-order/get-batch", {
                    params: {
                        sort: "asc",
                        warehouseID: this.selectedWarehouseArea.WarehouseID,
                        warehouseAreaID: this.selectedWarehouseArea.WarehouseAreaID,
                        skuCode: skuCode,
                        itemUnitID: itemUnitId
                    },
                })
            return response.data
        },
        customLabelWarehouse({ WarehouseCode, WarehouseName }) {
            return `${WarehouseCode} - ${WarehouseName}`;
        },
        customLabelWarehouseArea({ WarehouseAreaCode, WarehouseAreaName }) {
            return `${WarehouseAreaCode} - ${WarehouseAreaName}`;
        },
        async handleBoM() {
            if (this.selectedBom) {
                this.changedBom = true
                this.selectedPurpose = this.optionPurpose.find(option => option.value === this.selectedBom.Purpose)
                this.resetItemParent()
                this.resetItemLines()

                this.selectedWarehouse = null
                this.selectedWarehouseArea = null

                this.itemParent = {
                    optionSupplier: [],
                    optionItem: [],
                    optionUnit: [],
                    optionBatch: [],
                    subTotal: 0,
                    item_unit_id: this.selectedBom.ItemUnitID,
                    sku_code: this.selectedBom.SkuCode,
                    name: this.selectedBom.Name,
                    unit: this.selectedBom.Unit,
                    qty: this.selectedBom.Quantity,
                    qty_old: 0,
                    qty_uom: this.selectedBom.QtyUOM,
                    price: this.selectedBom.Price,
                    subTotalText: "0",
                    selected_items: {
                        sku_code: this.selectedBom.SkuCode,
                        item_name: this.selectedBom.Name
                    }
                };

                this.itemLines = this.selectedBom.ItemBillOfMaterialLines.map((el) => {
                    return {
                        optionItem: [],
                        optionUnit: [],
                        optionBatch: [],
                        item_unit_id: el.ItemUnitID,
                        sku_code: el.SkuCode,
                        name: el.Name,
                        unit: el.Unit,
                        qty_old: 0,
                        qty: el.Quantity,
                        qty_uom: el.QtyUOM,
                        price: 0,
                        batch: null,
                        subTotal: 0,
                        subTotalText: "0",
                        selected_items: {
                            item_name: el.Name
                        }
                    };
                });
            } else {
                this.isDisabled = true;
            }
        },
        async handleSearchLines(val, index, type) {
            if (!this.selectedPurpose || !this.selectedWarehouse) {
                return
            }

            const { WarehouseID, WarehouseAreaID } = this.selectedWarehouseArea
            const { value } = this.selectedPurpose
            const materialData = await this.getMaterialWo(val, type, value, WarehouseID, WarehouseAreaID)
            if (type == "parent") {
                this.itemParent.optionItem = materialData.records
            } else {
                this.itemLines[index].optionItem = materialData.records
            }
        },
        async getData(id) {
            const response = await this.$http
                .get("api/wms/v1/work-order/detail", {
                    params: {
                        id: id,
                    },
                })
            return response.data
            // this.$http
            //     .get("api/wms/v1/work-order/detail", {
            //         params: {
            //             id: id,
            //         },
            //     })
            //     .then((resp) => {
            //         if (resp.code == 200) {
            //             console.log(resp.data)
            //             this.code = resp.data.code;
            //             this.IDs = resp.data.id;
            //             this.description = resp.data.description;
            //             this.note = resp.data.note;
            //             this.requestDate = resp.data.request_date;
            //             this.executionDate = resp.data.execution_date;
            //             this.selectedBom = {
            //                 id: resp.data.item_bill_of_material_id,
            //                 Code: resp.data.item_bom_code,
            //             };
            //             this.selectedWarehouse = {
            //                 id: resp.data.warehouse_id,
            //                 name: resp.data.warehouse_name,
            //                 code: resp.data.code
            //             };
            //             this.workOrderStatus = resp.data.status
            //             this.selectedPurpose = {
            //                 value: resp.data.purpose_type,
            //                 name: resp.data.purpose_name
            //             }
            //             this.itemParent = [];
            //             this.itemParent = {
            //                 item_unit_id: resp.data.item_parent.item_unit_id,
            //                 sku_code: resp.data.item_parent.sku_code,
            //                 name: resp.data.item_parent.name,
            //                 unit: resp.data.item_parent.unit,
            //                 qty: resp.data.item_parent.quantity,
            //                 qty_old: resp.data.item_parent.quantity_old,
            //                 price: resp.data.item_parent.price,
            //                 qty_uom: resp.data.item_parent.qty_uom
            //             };

            //             if (resp.data.attachment_data) {
            //                 resp.data.attachment_data.forEach((el) => {
            //                     if (el.deleted == "") {
            //                         this.fileAttachment.push({
            //                             id: el.id,
            //                             NameFile: el.file_name,
            //                             PathFile: el.file_url,
            //                         });
            //                     }
            //                 });
            //             }

            //             this.itemLines = [];
            //             if (resp.data.work_order_lines) {
            //                 resp.data.work_order_lines.forEach((el) => {
            //                     this.itemLines.push({
            //                         id: el.id,
            //                         item_unit_id: el.item_unit_id,
            //                         sku_code: el.sku_code,
            //                         name: el.name,
            //                         unit: el.unit,
            //                         qty: el.quantity,
            //                         qty_old: el.quantity_old,
            //                         batch: el.batch,
            //                         price: el.price,
            //                         qty_uom: el.qty_uom
            //                     });
            //                 });
            //             }
            //         }
            //         this.$vs.loading.close();
            //     })
            //     .catch((error) => {
            //         this.$vs.loading.close();
            //         console.log(error);
            //     });
        },
    },
};
</script>
